import storage from 'store'
import {login} from '../../api/screen.js';

const user = {
  state: {
    token: '',
    user_id: '',
  },

  mutations: {
    SET_TOKEN: (state, token) => {
      state.token = token
    },
    SET_USERID: (state, user_id) => {
      state.user_id = user_id
    },
  },

  actions: {

    // 用户登录
    Login ({ commit }, userInfo) {
      return new Promise((resolve, reject) => {
        login(userInfo)
          .then(response => {
            const data = response.data
            // token保存7天
            storage.set('Authorization', data.token,)
            commit('SET_TOKEN', data.token)
            storage.set('ScreenUserId', data.user_id,)
            commit('SET_USERID', data.user_id)
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },


  }
}

export default user
