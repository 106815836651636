import * as request from '../utils/request'

const api = {
	showData: 'StatisticsDataNew/showData',
  login: 'StatisticsDataNew/login',
}

export const showData = (param) => {
	return request.get(api.showData,param)
}
export const login = (param) => {
  return request.get(api.login,param)
}
