<template>
	<div id="app">
		<router-view />
	</div>
</template>

<script>
	var designWidth = 1920 // ui界面宽度
	var remPX = 16 // 在屏幕宽度为375px的时候，根元素字体大小为4px
	var scale = window.innerWidth / designWidth // 当前屏幕与375屏幕的比例� // 动态设置根元素字体大小
	document.documentElement.style.fontSize = scale * remPX + 'px'
</script>

<style lang="scss">
	body {
		margin: 0;
	}

	#app {
		font-family: Avenir, Helvetica, Arial, sans-serif;
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
	}
</style>
